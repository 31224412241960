import React, { ReactElement } from "react";
import TemplateError from "mypage/src/components/templates/error";

const Custom404 = (): ReactElement => (
  <TemplateError
    message="ページが見つかりませんでした。"
    title="ページが見つかりませんでした"
  />
);

export default Custom404;
