import { Box } from "@chakra-ui/react";
import Image from "components/atoms/Image";
import React, { FC } from "react";
import ToTopButton from "~/components/atoms/button/ToTopButton";
import Layout from "mypage/src/components/layout";
import { MetaTag } from "mypage/src/components/modules/metaTag";
import { staticPath } from "mypage/src/lib/$path";

interface Props {
  message: string;
  title?: string;
}

const TemplateError: FC<Props> = ({ title = "エラーページ", message }) => (
  <Layout hideHeader>
    <>
      <MetaTag title={title} isNoIndexPage={true} />
      <Box className="content-wrap" pb={20}>
        <Box textAlign="center" mt="92px">
          <Image
            src={staticPath.img.sorry_png}
            width="120"
            height="120"
            alt="image sorry"
            display="inline"
            disableHover={true}
          />
        </Box>
        <Box
          color="black.700"
          fontWeight={700}
          fontSize={{ base: "18px", nr: "22px" }}
          lineHeight="36px"
          textAlign="center"
          m="40px auto 80px auto"
        >
          {message}
          <br />
          別のページを探してみて下さい。
        </Box>
        <ToTopButton />
      </Box>
    </>
  </Layout>
);

export default TemplateError;
