import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import React, { ReactElement, FC } from "react";
import styled from "styled-components";
import Button from "~/components/atoms/button";

const ToTopButton: FC = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Link href="/" passHref>
      <ButtonLink>
        <Button
          text={t(
            "common:button.to-top",
            {},
            { fallback: "トップページに戻る" }
          )}
        />
      </ButtonLink>
    </Link>
  );
};

const ButtonLink = styled.a`
  display: block;
  max-width: 382px;
  margin: 0 auto;
  text-decoration: none;
`;

export default ToTopButton;
